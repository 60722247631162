app-viewpatientdetails {
    .patient-summary .card .card-header {
        text-align: center;
    }

    .patient-summary .card .card-header h5 {
        font-size: 22px;
    }

    .bg-c-blue {
        color: #fff;
        background-color: #2c3e50;
        border-color: #2c3e50;
        padding: 15px;
    }

    .go-to-exam-btn-sec {
        display: block;
        text-align: right;
        margin-bottom: 15px;
    }

    .content-padding {
        padding: 5px 15px;
    }

    .label-colon {
        float: right;
    }

    .accordion-sec .card {
        box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.2);
    }

    .accordion-sec .card-header>button {
        width: 100%;
        text-align: left;
    }

    .accordion-sec .card-header {
        padding: 6px 0px;
    }

    h5.header {
        font-size: 18px;
        font-weight: bold;
    }

    .new-designed-card {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 1px 3px 10px #22343D05;
        border-radius: 10px;
        opacity: 1;
        padding: 15px;
        position: relative;
        border-top: none;

        .form-group {
            margin-bottom: 10px;
        }
    }

    hr:not([size]) {
        height: 0.1px;
    }

    .ama-certificate {
        margin-bottom: 60px;

        p {
            font-weight: bold;
            font-size: 14px;
        }
    }

    .insurance-items {
        background: #f9f9f9;
        padding-top: 12px;
        border-radius: 10px;
        margin-bottom: 15px;
        border-bottom: 2px solid #5CA6DB;
    }

    .appointmentHistory {
        background-color: #5CA6DB;
        color: white;
        border-radius: 10px;
        padding: 15px;
        margin: 15px 0px;
    }

    .continue-btn,
    .ok-book {
        background: #5CA6DB 0% 0% no-repeat padding-box;
        border-radius: 10px;
        text-align: center;
        font: normal normal bold 14px/19px 'SourceSansPro-r';
        letter-spacing: 0px;
        color: #FFFFFF;
        border: none;
        padding: 10px 45px;

        &:hover {
            border: none;
        }

        &:active {
            border: none;
            box-shadow: none;
        }
    }

    .ok-book {
        padding: 10px 20px;
    }

    label {
        font: normal normal normal 14px/19px 'SourceSansPro-r';
        // font-family: ", sans-serif ; 
        letter-spacing: 0px;
        text-transform: capitalize;
    }

    .patient-info-sec {
        .name {
            font: normal normal bold 18px/24px 'SourceSansPro-r';
            letter-spacing: 0px;
            color: #22343D;
        }

        .gender-age {
            font: normal normal normal 14px/19px 'SourceSansPro-sm';
            letter-spacing: 0px;
            color: #848DA5;
            margin-left: 15px;
        }

        .cc-names {
            font: normal normal normal 14px/19px 'SourceSansPro-sm';
            letter-spacing: 0px;
            color: #22343D;
            margin-right: 15px;
        }
    }

    .farm-actions {
        float: right;

        div {
            margin-left: 10px;
            cursor: pointer;
            background-color: #f1f1f1;
            padding: 8px;
            border-radius: 12px;
            display: flex;
            align-items: center;
            justify-content: center;

            i {
                font-size: 26px;
            }

            &:hover {
                background-color: #c5e7ff;
            }
        }
    }

    #book-appointment-modal,
    #doctors-modal {
        background-color: rgba(0, 0, 0, 0.1);
    }

    .modal-content {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 5px 25px 70px #22343d1a;
        border-radius: 20px;
    }
}

@media (max-width:768px) {
    app-viewpatientdetails .farm-actions {
        float: right !important;

        div {
            margin-top: 1px;
            position: absolute;
            right: 20px;
        }
    }
}
#patient-summary-sec {
    position: relative;

    @media (min-width:868px) {
        .mobile-navbar {
            display: none;
        }

    }

    @media (max-width:868px) {
        .mobile-navbar.bg-light {
            background-color: transparent !important;
        }

        .mobile-navbar {
            position: absolute;
            margin-top: -5px;
            margin-left: -25px;
            z-index: 99;

            .navbar-collapse {
                background-color: #fff;
                border: solid 1px #ccc;
                border-radius: 12px;
                padding: 10px 0 0;
            }

            .nav-item {
                padding: 0 10px;
            }

            .navbar-nav .nav-link {
                padding: 10px;
            }


        }

        .navbar-light .navbar-nav .show>.nav-link,
        .navbar-light .navbar-nav .nav-link.active {
            background-color: rgba(94, 186, 137, 0.3);
            color: #333;
            border-radius: 8px;
            padding: 7px 8px;
            font-size: 16px;

            &:focus {
                box-shadow: none;
            }
        }

        .navbar-light .navbar-nav .nav-link {
            color: #333
        }

    }
}