app-feedback {
    .feedback-check{
        width: 42%;
        margin: 0 auto;
        margin-bottom: 45px;
        padding: 35px;
        background-color: #fff;
        border-radius: 15px;
        box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.1);
        form.visit-exp-form{
            background: #5ca6db;
            padding: 30px;
            border-radius: 15px;
            color: #fff;
        }
        label.smiley-check{
            width: 75%;
            // padding: 12px 0px;
            height: 80px;
        }
        .response-content{
            margin-right: 12px;
            margin-bottom: 10px;
        }
        .btn-group > .btn-check:checked + .btn {
            background: #1d2a7b;
            color: #fff;
            border: none;
        }
    }
    .btn-lg {
        width: 100%;
    }
    
    .btn-light,
    .btn-check:checked {
        background-color: #fff;
    }

    .continue-btn {
        text-align: right;
        background: #5CA6DB 0% 0% no-repeat padding-box;
        font: normal normal bold 14px/19px 'SourceSansPro-r';
        color: #FFFFFF;
        border: none;
        // padding: 10px 45px;
        float: right;
        padding: 12px 35px;
        &:hover {
            border: none;
        }
    
        &:active {
            border: none;
            box-shadow: none;
        }
    }
    
    .button {
        text-align: end;
        margin-right: 10px;
        margin-bottom: 50px;
    }
    
    .ng2-tag-input[_ngcontent-gdd-c54]{
        border-bottom: none;
    }
    
    .btn-primary {
        background-color: #1d2a7b !important;
        color: #fff;
    }
}
::ng-deep {
    tag-input-form {
        display: none;
    }
}

@media(max-width:991px){
    app-feedback{
        .feedback-check{
            width: 100%;
            margin: 0 auto;
            margin-bottom: 45px;
            padding: 15px;
            background-color: #fff;
            border-radius: 15px;
            label.smiley-check{
                width: 80%;
            }
        }
    }
}