app-patient-launch-pad{
    .launchpad-items{
        width: 40%;
        margin: 0 auto;
        background: #ffff;
        padding: 35px;
        border-radius: 15px;
        margin-top: 45px;
        .all-items{
            .item{
                cursor: pointer;
                display: flex;
                align-items: center;
                .icon{
                    width: 48px;
                    height: 48px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 20px;
                    border-radius: 18px;
                    font-size: 30px;
                }
                h5{
                    margin: 0px;
                }
                .title{
                    width: 60%;
                }
                .angle-right{
                    font-size: 26px;
                    margin-left: 20px;
                }
            }
            .book-appnt{
                margin-bottom: 35px;
                .icon {
                    background: #c5e7ff;
                    color: #373a3c;
                }
            }
            .access-health-record{
                margin-bottom: 35px;
                .icon {
                    // background: #ebf5fe;
                    // color: #3f74f1;
                    background: #c5e7ff;
                    color: #373a3c;
                }
            }
            .join-tele{
                margin-bottom: 35px;
                // color: #b9b9b9;
                // cursor: not-allowed;
                .icon {
                    // background: #e7f5ff;
                    // color: #45b3ff;
                    background: #c5e7ff;
                    color: #373a3c;
                }
            }
            .pay-bill{
                .icon {
                    // background: #f4e9ff;
                    // color: #9226ff;
                    background: #c5e7ff;
                    color: #373a3c;
                }
            }
        }
        .logo-center{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 30px;
            a{
                h5{
                    padding-top: 15px; 
                    padding-left: 8px;
                    font-size: 28px !important;
                    font: normal normal bold 16px/22px;
                    letter-spacing: 0px;
                    color: #363636;
                }
            }
        }
    }
}

@media(max-width:991px){
    app-patient-launch-pad{
        .launchpad-items{
            width: 100%;
        }
    }
}