* {
    padding: 0;
    margin: 0;
}

// @font-face {
//     font-family: SourceSansPro-r !important;;
//     src: url(../fonts/SourceSansPro-Regular.ttf);
// }

// @font-face {
//     font-family: SourceSansPro-sm !important;;
//     src: url(../fonts/SourceSansPro-Semibold.ttf);
// }

// @font-face {
//     font-family: SourceSansPro-B !important;
//     src: url(../fonts/SourceSansPro-Black.ttf);
// }

body {
    background: url(../images/bg.jpg) repeat;
    background-color: #FAF8F6 !important;
    // height: 810px;
    width: 100%;
    background-size: 100% 100%;
    /* font-family: 'Roboto', sans-serif;
	font-family: 'Poppins', sans-serif;
	font-family: Helvetica, sans-serif;
    font-family: 'Source Sans 3', sans-serif !important;*/
    font-family: Arial, Helvetica, sans-serif !important;
    letter-spacing: 0.4px;
    color: #333;
    // overflow-y: hidden;
}

.main-bg a {
    color: #5CA6DB;
    font-weight: 600;
    text-decoration: none !important;

    &:hover {
        color: #0056b3;
        text-decoration: underline !important;
    }
}

::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px grey;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #1a154c;
}

/*buttons*/
.btn-primary {
    background: #5CA6DB !important;
    ;
    border-color: #5CA6DB !important;
    ;
    width: auto !important;
    padding: 12px 16px !important;
    border-radius: 6px !important;
    ;
    text-transform: uppercase !important;
    ;
    font-size: 13px !important;
    line-height: 10px !important;
    ;
    letter-spacing: 0.6px !important;
    ;
}

.btn-primary:hover {
    background: #1a154c !important;
    ;
    border-color: #1a154c !important;
    ;
}

.btn-primary:active {
    background: #1a154c !important;
    ;
    border-color: #1a154c !important;
    ;
}

.btn-sm {
    padding: 10px;
}

/*access screen*/
.access-tabs {
    background-color: #fff;
    width: 450px;
    height: 480px;
    border-radius: 40px;
    padding: 30px 60px;
    //   box-shadow: 0 2px 10px 0 rgba(255, 255, 255, 1);
    margin: 110px auto;
    position: relative;

    /* @media (max-width:768px) {
		width: 100%;
	    height: 524px;
	    padding: 15px 40px;
		margin: 50px auto;
		max-width: 400px;
	} */
}

/*access screen*/
.access-tabs-healthworks {
    background-color: #fff;
    width: 400px;
    height: auto;
    border-radius: 40px;
    padding: 30px 60px;
    //   box-shadow: 0 2px 10px 0 rgba(255, 255, 255, 1);
    margin: 110px auto;
    position: relative;

    .logo {
        width: 225px;
    }

}

.access-tabs-healthworks-otp {
    background-color: #fff;
    width: 400px;
    height: 452px;
    border-radius: 40px;
    padding: 30px 60px;
    //   box-shadow: 0 2px 10px 0 rgba(255, 255, 255, 1);
    margin: 110px auto;
    position: relative;

    .logo {
        width: 225px;
    }
}

.poweredby {
    position: absolute;
    // right: 35px;
    bottom: 14px;
    font-size: 14px;
}

.media-user {
    width: 45px;

    img {
        width: 35px;
        height: 35px;
        margin-left: 8px;
    }
}

.logo {
    background: url(../images/CAD-logo.png) no-repeat;
    width: 284px;
    height: 60px;
    margin: 0 auto 30px;
    background-size: cover;
}

.logo-healthworks {
    background: url(../images/HW-logo.png) no-repeat;
    width: 250px;
    height: 100px;
    margin: 0 auto 20px;
    background-size: cover;
}

.white-logo {
    background: url(../images/careeco-white-logo.png) no-repeat;
    width: 245px;
    height: 60px;
    margin: 0 auto 30px;
    background-size: cover;
}

.facility-logo-sec {
    width: 100%;
    height: 70px;
    margin: 0 auto 15px;
    text-align: center;
}

.facility-logo {
    max-width: 100%;
    object-fit: cover;
    max-height: 100%;
}

.access-tabs .media {
    border: solid 1px #1D2A7B;
    background-color: #fff;
    padding: 10px 15px;
    border-radius: 8px;
    margin-bottom: 15px;
    cursor: pointer;
}

/* .access-tabs .media.highlight{
	background-color: #ffffff;
} */
.access-tabs .media:hover {
    background-color: #C9E6FE;
    transition: all .5s;
    transform: scale(1.08);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.access-tabs .media img {
    background-color: #1D2A7B;
    width: 35px;
    height: 35px;
    border-radius: 8px;
    padding: 5px;
}

.access-tabs .media-body h4 {
    font-size: 14px;
    margin: 0;
    color: #1D2A7B;
    font-weight: 600;
}

.access-tabs .media-body p {
    font-size: 12px;
    color: #333;
    margin: 0;
    font-weight: 500;
}

.access-tabs .media:last-child {
    margin-bottom: 0;
}

.powered span {
    float: right;
    font-size: 14px;
    position: absolute;
    right: 0;
    bottom: -30px;
}

.powered h6 {
    font-weight: 600;
}

.powered {
    position: relative;
}

/*Footer*/
footer {
    height: 70px;
    background-color: #1D2A7B;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    padding: 10px;
    position: fixed;
    bottom: 0px;
    width: 100%;
    z-index: 10;
}

.footer-links a {
    color: #fff;
    font-weight: 500;
    text-decoration: none !important;
}

.footer-links ul li {
    display: inline-block;
    margin: 0px 5px;
    margin-bottom: 0;
}

.footer-links ul {
    margin-bottom: 0;
    padding: 0;
}

/*generic*/
.powered-txt {
    text-align: right;
    margin-bottom: 0;
    position: relative;
    top: -10px;
    font-size: 13px;
}

.hr-col {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid #333333;
    position: relative;
}

.hr-line {
    color: #333333;
    background-color: #fff;
    position: absolute;
    top: -13px;
    left: 45%;
    width: 40px;
    text-align: center;
}

.access-tabs .form-control {
    font-size: 14px;
    color: #808080;
    border-color: #9D9D9D;

}

/*Email*/
.access-tabs h6 {
    font-size: 14px;
    margin-bottom: 15px;
}

.email p {
    color: #808080;
    margin-bottom: 0;
}

.email p small {
    font-size: 13px;
}

.email label {
    font-size: 14px;
    color: #808080;
    font-weight: 500;
}

.email .form-control {
    font-size: 14px;
    color: #808080;
    width: calc(100% - 115px);
    display: inline-block;
    border-color: #9D9D9D;
    margin-right: 8px;
}

.link-text {
    color: #5CA6DB;
    display: block;
    font-size: 14px;
    font-weight: 600;
}

.success-text {
    color: #47D382 !important;
}

.event.active {
    .summary-text {
        background-color: #1D2A7B;
        border: solid 1px #1D2A7B;
    }
}

.summary-text {
    background-color: #5CA6DB;
    color: #fff !important;
    padding: 4px;
    border-radius: 4px;
    border: solid 1px #5CA6DB;
    font-size: 12px !important;
    font-weight: 500 !important;
    margin-right: 25px;
}

/*Home page*/
.home img {
    position: relative;
    bottom: 0;
}

.home .media-body h4 {
    font-size: 16px;
    font-weight: 500;
    color: #808080;
}

.home .media-body h4 b {
    color: #333;
}

.signout span {
    position: relative;
    top: -6px;
}

.home .user-inf0 {
    padding-left: 0;
}

.home .signout {
    padding-right: 0;
}

.signout .btn-primary {
    padding: 5px 16px !important;
}

.main-container {
    background-color: #fff;
    width: calc(100% - 120px);
    min-height: 500px;
    height: 100%;
    border-radius: 30px;
    padding: 30px 60px;
    //  box-shadow: 0 2px 10px 0 rgba(255, 255, 255, 1);
    margin: 50px auto;
    max-width: 1250px;
}

.home-tabs {
    padding: 20px 0 0;
    position: relative;
}

.home-tabs li.nav-item {
    width: calc(100% / 6 - 14px);
    margin-right: 16px;
}

.home-tabs li.nav-item:last-child {
    margin-right: 0;
}

.home-tabs .nav-pills .nav-link {
    color: #000;
    font-weight: 500;
    border: solid 0.125rem #5ca6db;
    border-radius: 5px;
    padding: 10px 8px;
    /* max-width: 174px; */
    text-align: center;
    /* margin-right: 10px; */
    font-size: 14px;
    min-height: 90px;
    display: flex;
    align-items: flex-start;
    padding-top: 32px;
    justify-content: center;
    font-family: Arial, Helvetica, sans-serif, sans-serif;
    font-weight: 600;

    &:hover {
        text-decoration: none !important;
    }
}

/* .home-tabs .nav-pills .nav-link:last-child{
	margin-right: 0;
} */
.home-tabs .nav-pills .nav-link.active,
.home-tabs .nav-pills .show>.nav-link {
    background-color: #1a154c;
    color: #fff !important;
    border-color: #1a154c;
    text-decoration: none !important;
}

.home-tabs .tab-content>.active {
    display: block;
    border: solid 1px #5ca6db;
    margin: 20px 0;
    padding: 20px;
    border-radius: 5px;
}

.home-tabs .tab-content {
    color: #333;
    font-size: 14px;
    overflow-x: hidden;
}

.tab-innercontent {
    max-height: 330px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 5px;
}

.home-tabs .tab-content .tab-innercontent>h5 {
    padding-bottom: 5px;
    border: 0;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    margin-bottom: 5px;
    font-size: 15px;
    font-family: Arial, Helvetica, sans-serif, sans-serif;
    font-weight: 600;
}

.tab-icon {
    background-color: #fff;
    border: solid 2px #5CA6DB;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    position: absolute;
    top: 4px;
    z-index: 9;
}

.tab-icon i {
    color: #5CA6DB;
    font-size: 20px;
    line-height: 28px;
}

.active .tab-icon {
    border-color: #fff;
    color: #fff;

    background-color: #1a154c;
}

.active .tab-icon i {
    color: #fff;
}

.call-icon {
    position: relative;
    top: 5px;
    color: #808080;
}

/*Demographics*/
.demo-form .formLabel,
.demo-form .controlLabel {
    width: calc(100% / 2 - 10px);
    margin-bottom: 0;
    font-size: 14px;
    color: #808080;
}

.demo-form .formLabel {
    float: left;
}

.demo-form .controlLabel {
    padding-left: 30px;
    font-family: Arial, Helvetica, sans-serif, sans-serif;
    font-weight: 600;
    color: #333;
}

.demo-form .form-group {
    margin-bottom: 0;
}

.label-colon {
    width: 10px;
}

.demo-form {
    margin-bottom: 10px;
}

/*Care events*/
/* .main-timeline-sec {
	padding: 45px;
	margin-top: 45px;
	border-radius: 10px;
} */
.select-stats {
    margin-top: 12px;
    margin-bottom: 0;
}

.select-stats .form-label {
    color: #000;
    font: normal normal normal 16px/16px Arial, Helvetica, sans-serif, sans-serif;
}

.select-stats .form-control {
    display: inline-block;
    font-size: 12px;
    padding: 4px !important;
    line-height: 12px;
    height: 30px;
    width: 150px;

}

.comment-text {
    font: normal normal normal 12px/16px Arial, Helvetica, sans-serif, sans-serif;
    margin-top: 6px;
    margin-bottom: 6px;
}

/* .main-timeline-sec .pName {
	font: normal normal normal 14px/19px ;
	letter-spacing: 0px;
	color: #363636;
	margin-bottom: 5px;
}
.main-timeline-sec .pName span.name-value {
	font: normal normal 600 14px/19px ;
	letter-spacing: 0px;
	color: #363636;
} */
.main-timeline-sec .main-title {
    font: normal normal bold 20px/33px;
    letter-spacing: 0px;
    color: #363636;
    display: flex;
    align-items: center;
    padding-top: 24px;
}

/* .main-timeline-sec .white-bg {
	background: #fff 0% 0% no-repeat padding-box;
	border-radius: 20px;
	padding: 10px 15px;
	margin-top: 20px;
} */
.main-timeline-sec .white-bg>h5 {
    font-size: 18px;
    letter-spacing: 0px;
    color: #000;
    border: none;
    padding-top: 6px;
    text-transform: capitalize;
    font: normal normal 16px/18px 'SourceSansPro-sm';
}

.btn-status-event {
    position: relative;
}

.btn-status-event .btn-status {
    color: #fff;
    font-size: 15px;
    font-weight: 600;
    padding: 4px 10px !important;
    text-align: center;
    position: absolute;
    top: -45px;
    right: 0;
}

@media (max-width: 991px) {
    .tab-innercontent {
        max-height: 330px;
        overflow-y: overlay;
    }
}

@media (min-width: 992px) {
    .demo-form .form-group {
        display: flex;
    }

    .btn-status-event .btn-status {
        transform: rotate(270deg);
        position: absolute;
        top: 83px;
        width: 208px;
        border-radius: 0px 0px 6px 6px;
        padding: 4px 0 !important;
        right: -143px;
        border-radius: 0px 0px 6px 6px;
        padding: 4px 0 !important;
    }

    .home-tabs .tab-content>.active {
        min-height: 300px;
    }

    .tab-innercontent {
        max-height: 400px;
    }

    .main-timeline-sec .patient-reference {
        // margin-top: 48px;
    }

    .main-timeline-sec .patient-reference h5 {
        padding-top: 0;
        font-size: 14px;
    }
}

@media (min-width: 992px) and (max-width:1050px) {
    .home-tabs .nav-item:nth-child(3) .nav-link {
        width: 120px;
    }
}

.btn-status-event .btn-status.completed,
.btn-status-event .btn-status.Completed {
    background-color: #2dcc70;
}

.btn-status-event .btn-status.schedule,
.btn-status-event .btn-status.Scheduled {
    background-color: #e67f22;
}

.btn-status-event .btn-status.error {
    background-color: #e84c3d;
}

.btn-status-event .btn-status.order,
.btn-status-event .btn-status.Ordered {
    background-color: #589CFA;
}

.main-timeline-sec .white-bg.patient-reference .patient-tabs .nav-tabs {
    background-color: #fff;
    border-radius: 16px;
    padding: 4px;
    box-shadow: 0 4px 4px 3px rgba(0, 0, 0, 0.1);
    position: relative;
    bottom: unset;
    border: solid 1px rgba(0, 0, 0, .15);
    left: unset;
    width: 100%;
}

.main-timeline-sec .white-bg.patient-reference .patient-tabs .nav-tabs .nav-link {
    color: #7A7A7A;
    font-size: 12px;
    padding: 4px 6px;
    font-weight: 600;
    text-align: center;
    letter-spacing: 1px;
}

.main-timeline-sec .white-bg.patient-reference .patient-tabs .nav-tabs .nav-link.active {
    background-color: #5ca6db;
    color: #fff !important;
    border-radius: 16px;
    text-decoration: none !important;
}

.main-timeline-sec .white-bg.patient-reference .patient-tabs .tab-content>.active {
    display: block;
    background: #fff;
    min-height: 200px;
    margin-top: 12px;
    padding: 12px;
    font-size: 12px;
    border: none;
    box-shadow: 0 4px 4px rgba(0, 0, 0, .15);
}

.patient-reference h6 {
    font-size: 14px;
    color: #333;
    margin-top: 6px !important;
}

.main-timeline-sec .all-events {
    position: relative;
}

.main-timeline-sec .all-events:before {
    content: "";
    position: absolute;
    width: 2px;
    height: 100%;
    background-color: #cbcbcb;
    top: 0;
    left: -16px;
}

/* .main-timeline-sec .events-timeline-content {
	background-color: #dfedf8;
	padding: 10px;
	border-radius: 10px;
} */
.main-timeline-sec .filter-title {
    margin-bottom: 30px;
}

.main-timeline-sec .full-width {
    width: 100%;
}

.main-timeline-sec .btn-primary-careeco {
    padding: 10px 20px !important;
}

.main-timeline-sec .events-timeline .all-events-timeline {
    padding: 0 0 0 84px;
    /* height: 500px;
	overflow-y: auto; */
}

.main-timeline-sec .events-timeline .all-events-timeline .event {
    background: #fff 0% 0% no-repeat padding-box;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.23);
    border: solid 1px #5ca6db;
    border-radius: 10px;
    padding: 6px 55px 6px 6px;
    cursor: pointer;
    margin-top: 25px !important;
    min-height: 210px;
    color: #000;
    margin-left: 8px;
    position: relative;
}

.main-timeline-sec .events-timeline .all-events-timeline .event.active {
    background-color: rgba(201, 230, 254, .4);
}

.main-timeline-sec .events-timeline .all-events-timeline .event .event-header-sec .doc-img {
    background-color: #dfedf8;
    width: 26px;
    height: 26px;
    border-radius: 4px;
    padding: 4px;
    float: left;
}

.main-timeline-sec .events-timeline .all-events-timeline .event .event-header-sec .doc-img i {
    color: #5ca6db;
    font-size: 24px;
    position: relative;
    top: -2px;
    left: -3px;
}

.main-timeline-sec .events-timeline .all-events-timeline .event .event-header-sec .event-doc {
    padding-left: 10px;
    float: right;
}

.main-timeline-sec .events-timeline .all-events-timeline .event .event-header-sec .event-title,
.main-timeline-sec .events-timeline .all-events-timeline .event .event-header-sec .event-title2 {
    font: normal normal bold 14px/19px Arial, Helvetica, sans-serif, sans-serif;
    letter-spacing: 0px;
    color: #000;
    display: block;
    margin-bottom: 0;
}

.main-timeline-sec .events-timeline .all-events-timeline .event .event-header-sec .event-title2 {
    font-size: 14px;
    font-weight: 500;
}

.main-timeline-sec .events-timeline .all-events-timeline .event .event-header-sec .event-status {
    text-align: right;
    float: right;
    border: solid 1px #fff;
    display: inline-block;
    background-color: #fff;
    padding: 3px 6px;
    color: #2b3278;
    border-radius: 5px;
    font-size: 14px;
    position: relative;
    top: -5px;
}

.main-timeline-sec .events-timeline .all-events-timeline .event .event-header-sec .event-status.cancel {
    color: #f00;
}

.main-timeline-sec .events-timeline .all-events-timeline .event .event-header-sec .event-status.reschedule {
    color: #1a154c;
}

.main-timeline-sec .events-timeline .all-events-timeline .event .event-header-sec .event-status.confirm {
    color: #ff9f43;
}

.main-timeline-sec .events-timeline .all-events-timeline .event .event-header-sec .event-highlight .event-status {
    background-color: #5ca6db;
    color: #fff;
}

.main-timeline-sec .events-timeline .all-events-timeline .event .event-header-sec .phone {
    font: normal normal 600 15px/17px Arial, Helvetica, sans-serif, sans-serif;
    letter-spacing: 0px;
    display: inline-block;
}

.main-timeline-sec .events-timeline .all-events-timeline .event .event-header-sec .phone i {
    color: #5ca6db;
    font-size: 24px;
    position: relative;
    top: 4px;
}

.main-timeline-sec .events-timeline .all-events-timeline .event .event-header-sec .visit-date {
    position: relative;
    margin-top: -50px;
}

.main-timeline-sec .events-timeline .all-events-timeline .event .event-header-sec .visit-date .value {
    font: normal normal 14px/17px Arial, Helvetica, sans-serif, sans-serif;
    position: absolute;
    left: -112px;
    max-width: 60px;
    top: -10px;
}

.main-timeline-sec .events-timeline .all-events-timeline .event .event-header-sec .visit-date .value i {
    display: block;
    font-size: 10px;
}

.main-timeline-sec .events-timeline .all-events-timeline .event .event-header-sec .visit-date .clock {
    position: absolute;
    left: -39px;
    z-index: 2;
    top: -2px;
    font-size: 18px;
    color: #fff;
}

.main-timeline-sec .events-timeline .all-events-timeline .event .event-header-sec .visit-date:before {
    margin-left: -48px;
    content: "";
    position: absolute;
    width: 36px;
    height: 36px;
    margin-top: -10px;
    border-radius: 50%;
    background-color: #5ca6db;
    z-index: 1;
}

.main-timeline-sec .events-timeline .all-events-timeline .event .event-header-sec.doc-details {
    display: flex;
    align-items: flex-start;
    margin-top: 8px;
}

.main-timeline-sec .events-timeline .all-events-timeline .event .addess span {
    font: normal normal normal 14px/18px Arial, Helvetica, sans-serif, sans-serif;
    letter-spacing: 0px;
    margin-bottom: 4px;
    font-weight: 500;
}

@media (max-width: 991px) {
    .main-timeline-sec .events-timeline .all-events-timeline .event .addess {
        font: normal normal bold 12px/19px Arial, Helvetica, sans-serif, sans-serif;
    }
}

.main-timeline-sec .events-timeline .all-events-timeline .event+.event {
    margin-top: 15px !important;
}

.main-timeline-sec .events-timeline .all-events-timeline .event .btn-primary-careeco {
    padding: 4px 16px !important;
    font-size: 11px !important;
    margin-bottom: 4px !important;
    border-radius: 4px !important;
}

.main-timeline-sec .events-timeline .all-events-timeline .event.active .event-header-sec .visit-date:before {
    background-color: #1a154c;
}

.main-timeline-sec .events-timeline .all-events-timeline .event.active .event-header-sec .event-title {
    color: #000;
}

.main-timeline-sec .events-timeline .all-events-timeline .event.active .event-header-sec .phone {
    color: #000;
}

.main-timeline-sec .events-timeline .all-events-timeline .event.active .event-header-sec.event-highlight:before {
    background-color: #1a154c;
}

.main-timeline-sec .events-timeline .all-events-timeline .event.active .addess {
    color: #000;
}

/* .main-timeline-sec .events-timeline .all-events-timeline .event.active .what-to-expect .whatexpect-title {
	color: #000;
}
.main-timeline-sec .events-timeline .all-events-timeline .event.active .what-to-expect .whatexpect-value {
	color: #000;
} */
.main-timeline-sec .patient-reference {
    // height: 96%;
    height: auto;
    border: solid 2px #5ca6db;
    border-radius: 10px;
    padding: 6px 10px;
}

.main-timeline-sec .form-label.status-txt {
    margin-bottom: 5px;
}

.home-tabs .patient-tabs li.nav-item {
    width: calc(100% / 4 - 15px);
    display: block;
}

/*Docs */
.tab-innercontent p {
    margin-bottom: 10px;
}

.docs {
    padding: 0;
    margin: 0;
}

.docs li {
    list-style-type: none;
    display: inline-block;
    margin-right: 10px;
    font-size: 24px;
}

.home .patient-tabs .media-body h4 {
    font-size: 13px;
    color: #000;
}

.close {
    color: #000;
    opacity: 1;
    line-height: 16px;
}

.dynamic-btns {
    position: absolute;
    right: 53px;
    bottom: 5px;
}

.main-timeline-sec .patient-reference h6 {
    color: #333;
}

/*Responsive*/

@media (max-width:991px) {

    footer {
        height: auto;
        position: fixed;
        // bottom: calc(100vh/2 - 589px);
        bottom: 0;
        left: 0;

    }

    body {
        overflow-y: auto;
    }

    .call-icon {
        font-size: 16px;
    }

    .main-timeline-sec .patient-reference {
        margin-top: 20px;
    }

    .powered-txt {
        top: 0;
    }

    .logo {
        width: 200px;
        height: 50px;
        margin-top: 15px;
    }

    .access-tabs {
        width: 100%;
        height: 100%;
        padding: 15px 40px;
        margin: 120px auto;
        max-width: 400px;

        &.main {
            min-height: 450px;
        }

    }

    .access-tabs-healthworks {
        width: 100%;
        height: 100%;
        padding: 15px 40px;
        margin: 120px auto;
        max-width: 400px;
        height: 355px;

    }

    .access-tabs-healthworks-otp {
        width: 100%;
        height: 100%;
        padding: 15px 40px;
        margin: 120px auto;
        max-width: 400px;
        // height: 355px;

    }

    .inner-page {
        min-height: 300px;
    }

    footer {
        padding: 10px;
        height: 110px;
        text-align: center;
        // bottom: 33px;
    }

    .footer {
        max-width: 400px;
        margin: 0 auto;
    }

    .footer-links a {
        display: block;
        text-align: center;
    }

    .email .btn {
        margin-top: 0;
        font-size: 12px !important;
    }

    /*mob menu*/
    .nav {
        position: absolute;
        z-index: 99;
        bottom: -344px;
        background-color: #5CA6DB;
        display: flex;
        clear: both;
        align-items: flex-start;
        justify-content: center;
        width: 100vw;
        left: -53px;
    }

    .home-tabs .nav-pills .nav-link {
        padding: 4px;
        max-width: 52px;
        font-size: 10px;
        min-height: 40px;
        display: block;
    }

    .home-tabs .nav-pills .nav-item:nth-child(3) .nav-link {
        max-width: 86px;
    }

    .home-tabs .nav-pills .nav-item:nth-child(5) .nav-link {
        max-width: 80px;
    }

    .home-tabs li.nav-item {
        width: calc(100% / 6 - 18px);
        /* width: auto; */
        display: inline-flex;
    }

    .tab-icon {
        border: none;
        position: relative;
        top: 0;
        background-color: transparent;
        margin: 0 auto;
    }

    .tab-icon i {
        color: #fff;
        font-size: 30px;
    }

    .active .tab-icon {
        background-color: transparent;
    }

    .home-tabs .nav-pills .nav-link.active,
    .home-tabs .nav-pills .show>.nav-link {
        background-color: transparent;
    }

    .active .tab-icon i,
    .home-tabs .nav-pills .nav-link.active {
        color: #1a154c;
        border-color: transparent;
    }

    .home-tabs .nav-pills .nav-link {
        color: #fff;
    }

    .home-tabs .nav-pills .nav-link span {
        display: block;
        text-align: center;
    }

    .home img {
        margin-right: 10px !important;
        bottom: 12px;
    }

    .home .media-body h4 {
        font-size: 15px;
        position: relative;
        top: -5px;

    }

    .home .user-inf0 {
        width: 60%;
    }

    .home .signout {
        width: 40%;
    }

    .main-container {
        padding: 20px 35px;
        width: calc(100% - 35px);
    }

    .signout .btn {
        padding: 6px 6px !important;
        border-radius: 6px;
        text-transform: uppercase;
        font-size: 9px !important;
        /* position: relative;
	top:-13px; */
    }

    .signout i {
        font-size: 16px;
    }

    // footer {
    //     position: fixed;
    // }

    .home-tabs .tab-content>.active {
        margin: 0;
    }

    .home-tabs .tab-content h5 {
        font-size: 16px;
    }

    .demo-form .controlLabel {
        font-size: 12px;
    }

    .demo-form {
        margin-bottom: 8px;
    }

    /*care journey*/

    .main-timeline-sec .events-timeline .all-events-timeline .event .event-header-sec .visit-date:before {
        margin-top: 37px;
        width: 26px;
        height: 26px;
        margin-left: -40px;
    }

    .main-timeline-sec .events-timeline .all-events-timeline .event .event-header-sec .visit-date .value {
        left: -108px;
        top: 38px;
    }

    .main-timeline-sec .events-timeline .all-events-timeline .event .event-header-sec .visit-date .clock {
        left: -35px;
        top: 41px;
        font-size: 16px;
    }

    .main-timeline-sec .events-timeline .all-events-timeline .event {
        padding: 10px 35px 10px 10px;
        min-height: 170px;
    }

    .btn-status-event .btn-status {
        top: 78px;
        right: -123px;
        transform: rotate(-90deg);
        border-radius: 0px 0px 10px 10px;
        width: 207px;
    }

    .main-timeline-sec .events-timeline .all-events-timeline .event .event-header-sec .visit-date {
        top: -50px;
        left: -8px;
    }

    .home-tabs .tab-content {
        min-height: 378px;
    }

    .nav {
        // bottom: 107px;
        bottom: -483px;
        left: -53px;
        padding-top: 15px;
        // height: 30%;
        // position: fixed;

    }
}

@media (max-width:768px) {
    .nav {
        bottom: -373px;
        // bottom: 108px;
        // left: 0px;
        // position: fixed;
    }

    footer {
        // bottom: calc(50vh - 313px);
        position: fixed;
        bottom: 0;
    }

    .home-tabs .tab-content {
        min-height: 270px;
    }

    .link-text {
        font-size: 14px;
    }

    .select-stats .form-label {
        font-size: 13px;
    }

    .main-timeline-sec .patient-reference h6 {
        font-size: 14px;
        border: none;
    }

    .home-tabs .tab-content .tab-innercontent h5 {
        font-size: 14px;
    }

    .home .media-body h4 {
        font-size: 13px;
    }

    .main-timeline-sec .events-timeline .all-events-timeline {
        padding: 0 0 0 28px;
    }

    .main-timeline-sec .events-timeline .all-events-timeline .event .event-header-sec .visit-date .value {
        left: 0;
        top: 16px;
        MAX-WIDTH: 200px;
        WIDTH: 230px;
        DISPLAY: block;
        text-align: left;
    }

    .main-timeline-sec .events-timeline .all-events-timeline .event .event-header-sec .visit-date:before {
        margin-top: 15px;
    }

    .main-timeline-sec .events-timeline .all-events-timeline .event .event-header-sec .visit-date .clock {
        top: 20px;
    }

    .main-timeline-sec .events-timeline .all-events-timeline .event .event-header-sec .visit-date .value i {
        display: inline-block;
    }

    .main-timeline-sec .events-timeline .all-events-timeline .event+.event {
        margin-top: 30px !important;
    }

    .main-timeline-sec .events-timeline .all-events-timeline .event {
        margin-top: 33px !important;
        padding: 10px 26px 10px 10px;
    }

    .home-tabs .tab-content>.active {
        padding: 0;
        border: NONE;
    }

    .main-timeline-sec .events-timeline .all-events-timeline .event .event-header-sec .event-title,
    .main-timeline-sec .events-timeline .all-events-timeline .event .event-header-sec .event-title2,
    .main-timeline-sec .events-timeline .all-events-timeline .event .event-header-sec .phone,
    .main-timeline-sec .events-timeline .all-events-timeline .event .addess {
        font-size: 11px;
    }

    .comment-text {
        font-size: 11px;
    }

    .btn-status-event .btn-status {
        top: 78px;
        right: -114px;
        width: 208px;
    }

    .main-timeline-sec .events-timeline .all-events-timeline .event .event-header-sec .phone i {
        font-size: 16px;
    }

    .main-timeline-sec .events-timeline .all-events-timeline .event .addess span {
        font-size: 12px;
    }

    footer {
        padding: 15px 10px;
    }

}

@media (min-width:1200px) {
    // footer {
    //     position: fixed;
    // }

}

@media (max-width:574px) {
    .access-tabs .media img {
        margin-right: 10px !important;
    }

    .poweredby {
        font-size: 13px;
    }

    .access-tabs .media-body h4 {
        font-size: 13px;
    }

    .home img {
        position: relative;
        bottom: 5px;
    }

    body {
        height: 100vh;
        overflow-y: hidden;
    }

    // .powered-txt{
    //     top:30px;
    // }
    // .home-tabs .tab-content{
    //     min-height: 250px;
    // }
    .tab-innercontent {
        max-height: 250px;
    }

    .btn-status-event .btn-status {
        top: 63px;
        width: 168px;
        right: -94px;
    }

    .main-timeline-sec .patient-reference h6 {
        font-size: 12px;
        font-weight: 600;

    }

    .patient-reference .btn-primary-careeco {
        font-size: 11px !important;
        padding: 8px 13px !important;
    }

    .patient-reference .tab-innercontent p {
        font-size: 11px;
    }

    .home-tabs .patient-tabs li.nav-item {
        width: calc(25% - 4px);
    }

    .home-tabs .tab-content li.nav-item:first-child {
        width: 72px;
    }

    .main-timeline-sec .white-bg.patient-reference .patient-tabs .nav-tabs .nav-link {
        font-size: 8px;
        text-decoration: none;
    }

    .signout span {
        position: relative;
        top: -4px;
    }

    .pay-navigate i {
        font-size: 20px !important;
    }

    .home .user-inf0 {
        width: 54%;
    }

    .home .signout {
        width: 44%;
    }

    .home .media-body h4 {
        font-size: 12px;
    }

    .access-tabs {
        padding: 15px 35px;

    }

    .footer-links ul li {
        display: block;
    }

    footer {
        height: auto;
        bottom: calc(100vh/2 - 290px);
        display: none;
    }

    footer p {
        margin-bottom: 0;
    }

    .footer {
        max-width: 100%;
    }

    .signout .btn {
        font-size: 9px !important;
    }

    .main-timeline-sec .events-timeline .all-events-timeline .event .btn-primary-careeco {
        padding: 4px 10px !important;
    }

    .main-timeline-sec .events-timeline .all-events-timeline .event {
        padding: 6px 26px 2px 10px;
        min-height: 170px;
    }

    .comment-text {
        font-size: 10px;
        margin: 0 !important;
        line-height: 14px;
    }

    .main-timeline-sec .events-timeline .all-events-timeline {
        padding: 0 0 0 20px;
    }

    .main-timeline-sec .all-events:before {
        left: -12px;
    }

    .main-timeline-sec .events-timeline .all-events-timeline .event .event-header-sec .visit-date .clock {
        left: -27px;
    }

    .main-timeline-sec .events-timeline .all-events-timeline .event .event-header-sec .visit-date:before {
        margin-left: -32px;
    }

    .main-container.home {
        padding: 20px 26px;
    }

    .main-timeline-sec .events-timeline .all-events-timeline .event .event-header-sec .visit-date .value {
        top: 20px;
    }

    .footer-links a,
    .footer {
        font-size: 12px;
    }

    .demo-form .formLabel,
    .demo-form .controlLabel {
        font-size: 13px;
    }

    .nav {
        left: -26px;
        bottom: unset;
        top: calc(100vh - 304px);
        // position: fixed;
        // left: 0px;
        // bottom: 0px;
    }

    .home-tabs li.nav-item {
        width: calc(100% / 6 - 2px);
        margin-right: 0;
    }


    .home-tabs .nav-pills .nav-item:first-child .nav-link {
        max-width: 72px;
    }

    .main-timeline-sec .events-timeline .all-events-timeline .event .event-header-sec .event-title,
    .main-timeline-sec .events-timeline .all-events-timeline .event .event-header-sec .event-title2,
    .main-timeline-sec .events-timeline .all-events-timeline .event .event-header-sec .phone,
    .main-timeline-sec .events-timeline .all-events-timeline .event .addess {
        line-height: 14px;
    }

    .main-timeline-sec .events-timeline .all-events-timeline .event .event-header-sec.doc-details {
        margin-top: 5px;
    }

    .home {
        background-color: #fff;
        width: calc(100% - 0px);
        min-height: 500px;
        height: 100%;
        border-radius: 0;
        padding: 30px 60px;
        box-shadow: none;
        margin: 50px auto;
        max-width: 1250px;
    }
}

.error,
.required {
    color: red;
}

div#book-appointment-modal,
#cancel-appointment-page-modal,
#exceptional-error-modal {
    background-color: rgba(0, 0, 0, 0.6);
}

.form-check.form-check-inline {
    display: inline-flex;
}

app-patient-details-verify+app-footer footer,
app-mobile-email-verify+app-footer footer,
app-otp-verification+app-footer footer,
app-patient-portal+app-footer footer,
app-schedule-appointment+app-footer footer,
app-multiple-users-verify+app-footer footer {
    bottom: 0;
}

@media (max-width:574px) {
    .tab-name {
        font-size: 7px;
    }

    .main-timeline-sec .white-bg.patient-reference .patient-tabs .nav-tabs {
        background-color: #fff;
        border-radius: 16px;
        padding: 4px;
        box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.1);
        position: relative;
        bottom: unset;
        border: solid 1px rgba(0, 0, 0, 0.15);
        top: -2px;
        left: unset;
        width: 100%;
    }


}

::ng-deep {
    .text-info {
        color: #e7b000 !important;
    }
}

.text-danger {
    font-size: 12px;
}

.required {
    font-size: 12px !important;
}

#visor-patient-demographics,
#visor-health-history,
#visor-new-care,
#visor-schedule-appointment,
#injury-que-selected {
    .ng-select .ng-arrow-wrapper {
        background-color: #4425F8;
        height: 37px;
        width: 28px;
        padding-top: 7px;
        padding-right: 0px;
    }

    .ng-select .ng-arrow-wrapper .ng-arrow {
        border-color: #fff transparent transparent !important;
    }

    .ng-select .ng-arrow-wrapper:hover .ng-arrow {
        border-top-color: #fff !important;
    }

    .ng-select.ng-select-opened>.ng-select-container .ng-arrow {
        border-color: transparent transparent #fff !important;
    }
    app-patient-demographics+app-footer footer {
        display: none !important;
    }
}

@media screen and (max-width:1024px) and (orientation:landscape){
    footer{
        display: none;
    }
}