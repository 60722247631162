app-care-events {
    .patient-life-cycle {
        position: relative;

        .main-timeline-sec {
            background: rgba(91,167,220,.2) 0% 0% no-repeat padding-box;
            // height: 100vh;
            padding: 45px;
            margin-top: 45px;
            border-radius: 10px;

            .pName {
                font: normal normal normal 14px/19px 'SourceSansPro-sm';
                letter-spacing: 0px;
                color: #363636;
                margin-bottom: 5px;

                span.name-value {
                    font: normal normal 600 14px/19px 'SourceSansPro-r';
                    letter-spacing: 0px;
                    color: #363636;
                }
            }

            .main-title {
                font: normal normal bold 24px/33px 'SourceSansPro-r';
                letter-spacing: 0px;
                color: #363636;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .white-bg {
                background: #FFFFFF 0% 0% no-repeat padding-box;
                border-radius: 20px;
                padding: 30px;
                margin-top: 20px;

                h5 {
                    font: normal normal bold 16px/22px 'SourceSansPro-r';
                    letter-spacing: 0px;
                    color: #363636;
                }
            }

            .filter-title {
                margin-bottom: 30px;
            }

            .full-width {
                width: 100%;
            }

            .events-timeline {
                .all-events {
                    background: #d1d5df33 0% 0% no-repeat padding-box;
                    margin: 20px 0px;
                    padding: 20px;
                    height: 500px;
                    overflow-y: auto;
                    border-left: 2px solid #c5c5c5;
                    position: relative;

                    .event {
                        background: #fff 0% 0% no-repeat padding-box;
                        box-shadow: 5px 7px 25px #22343D1A;
                        border-radius: 10px;
                        padding: 15px;
                        cursor: pointer;

                        .event-header-sec {
                            .event-title {
                                font: normal normal bold 14px/19px 'SourceSansPro-r';
                                letter-spacing: 0px;
                                color: #22343D;
                            }

                            .event-status {
                                text-align: right;
                                float: right;
                                border: solid 1px #fff;
                                display: inline-block;
                                background-color: #fff;
                                padding: 3px 6px;
                                color: #2B3278;
                                border-radius: 5px;
                                font-size: 13px;
                                position: relative;
                                top: -5px;

                                &.cancel {
                                    color: #ff0000;
                                }

                                &.reschedule {
                                    color: #1a154c;
                                }

                                &.confirm {
                                    color: #ff9f43;
                                }
                            }

                            .event-highlight {
                                .event-status {
                                    background-color: #5CA6DB;
                                    color: #fff;
                                }
                            }


                            .phone {
                                font: normal normal 600 12px/17px 'SourceSansPro-r';
                                letter-spacing: 0px;
                                display: inline-block;
                                color: #22343D;
                                // margin-left: 20px;
                            }

                            .visit-date {
                                font: normal normal 600 12px/17px 'SourceSansPro-r';
                                letter-spacing: 0px;
                                color: #22343D;
                                display: inline-block;
                                float: right;
                            }

                            &.event-highlight:before {
                                margin-left: -30px;
                                content: "";
                                position: absolute;
                                width: 24px;
                                height: 24px;
                                margin-top: 60px;
                                border-radius: 50%;
                                background-color: #c5c5c5;
                                z-index: 9;
                            }
                        }

                        .addess {
                            font: normal normal normal 12px/17px 'SourceSansPro-sm';
                            letter-spacing: 0px;
                        }

                        .what-to-expect {

                            // display: flex;
                            .whatexpect-title {
                                font: normal normal normal 12px/17px 'SourceSansPro-sm';
                                width: auto;
                            }

                            .whatexpect-value {
                                font: normal normal 600 14px/17px 'SourceSansPro-r';
                                letter-spacing: 0px;
                                margin-left: 10px;
                            }
                        }

                        &:before {
                            left: -30px;
                            content: "";
                            position: absolute;
                            border-bottom: solid 2px #c5c5c5;
                            width: 50px;
                            margin-top: 80px;
                        }


                    }

                    .event.active {
                        background: #5CA6DB 0% 0% no-repeat padding-box;

                        .event-header-sec {
                            .event-title {
                                color: #FFFFFF;
                            }

                            .phone {
                                color: #FFFFFF;
                            }

                            .visit-date {
                                color: #f0f0f0;
                            }

                            &.event-highlight:before {

                                background-color: #1a154c;

                            }
                        }

                        .addess {
                            color: #FFFFFF;
                        }

                        .what-to-expect {
                            .whatexpect-title {
                                color: #f0f0f0;
                            }

                            .whatexpect-value {
                                color: #FFFFFF;
                            }
                        }

                        &:before {
                            border-bottom: solid 2px #1a154c;
                        }
                    }
                }
            }

            .patient-reference {
                height: 96%;
            }

            .form-label.status-txt {
                margin-bottom: 5px;
            }
        }
    }

    .text-right {
        text-align: right;
    }

    .error {
        color: red;
    }

    .cancel-appointment-page-btn {
        background: #5CA6DB 0% 0% no-repeat padding-box;
        border-radius: 10px;
        text-align: center;
        font: normal normal bold 14px/19px 'SourceSansPro-r';
        letter-spacing: 0px;
        color: #FFFFFF;
        border: none;
        padding: 10px 45px;

        &:hover{
            background-color: #1a154c;
           color:#fff;
       }

        &:active {
            border: none;
        }
    }

    .validations {
        width: 50%;
        margin: 0 auto;
    }

    #book-appointment-modal,
    #doctors-modal {
        background-color: rgba(0, 0, 0, 0.1);
    }

    .modal-content {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 5px 25px 70px #22343d1a;
        border-radius: 20px;
    }

    .ok-book {
        background: #5CA6DB 0% 0% no-repeat padding-box;
        border-radius: 10px;
        text-align: center;
        font: normal normal bold 14px/19px 'SourceSansPro-r';
        letter-spacing: 0px;
        color: #FFFFFF;
        border: none;
        padding: 10px 45px;

        &:hover{
            background-color: #1a154c;
           color:#fff;
       }

        &:active {
            border: none;
            box-shadow: none;
        }
    }

    .ok-book {
        padding: 10px 20px;
    }
}

.academy-images {
    margin-top: 40px !important;

    img {
        width: 45%;
        border-radius: 10px;
    }
}
.dynamic-btns{
    .btn-primary-careeco{
        background: #fff;
        color: #1a154c;
        border:solid 1px #5CA6DB;
    }
}
@media(max-width:991px) {
    app-care-events {
        .patient-life-cycle {
            .container {
                padding: 0px;
            }

            .main-timeline-sec {
                padding: 15px;

                .white-bg {
                    padding: 15px;
                }

                .events-timeline {
                    .all-events {
                        padding: 0px 5px 0 15px;
                        border: none;
                        margin: 0px;
                        height: 360px;

                        .event {
                            .event-header-sec {
                                text-align: left;

                                .phone {
                                    margin-left: 0px;
                                }

                                .visit-date {
                                    float: none;
                                }

                                &.event-highlight:before {
                                    margin-left: -30px;
                                    content: "";
                                    position: absolute;
                                    width: 24px;
                                    height: 24px;
                                    margin-top: 60px;
                                    border-radius: 50%;
                                    background-color: #c5c5c5;
                                    z-index: 9;
                                }
                            }

                            .what-to-expect {
                                display: block;

                                .whatexpect-value {
                                    margin-left: 0px;
                                    margin-top: 10px;
                                }
                            }

                            &:before {
                                left: -30px;
                                content: "";
                                position: absolute;
                                border-bottom: solid 2px #c5c5c5;
                                width: 50px;
                                margin-top: 80px;
                            }
                        }

                    }
                }
            }
        }

        .text-right,
        .mb-center {
            // text-align: left;
            margin-top: 10px;
        }

        .validations {
            width: 100%;
            margin: 0 auto;
        }

        .facility-logo {
            float: none !important;
            display: block;
            margin: 0 auto;
        }

        .academy-images {
            margin-top: 30px !important;

            img {
                width: 75% !important;
            }
        }
    }
}

@media(max-width:875px) {
    app-care-events {
        .patient-life-cycle .hidden-xs {
            display: none !important;
        }

        .patient-life-cycle .vissible-xs {
            display: block !important;
        }

        .btn.btn-default {
            background-color: #5CA6DB;
            color: #fff;
            margin: 25px 0;
            border-color: #5CA6DB;
        }

        .btn.btn-default:hover {
            background-color: #2B3278;
            border-color: #2B3278;
        }

        .close {
            background-color: transparent;
            border: none;
            position: absolute;
            right: 12px;
            top: -4px;
            font-size: 24px;
        }
        #patient-refference {
            .modal-body {
                height: 500px;
                overflow-y: scroll;
            }
        }
    }
}

.patient-life-cycle .hidden-xs {
    display: block;
}

.patient-life-cycle .vissible-xs {
    display: none;
}