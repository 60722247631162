/* You can add global styles to this file, and also import other style files */

// Custom.scss
// Option A: Include all of Bootstrap

// Include any default variable overrides here (though functions won't be available)

@import "../node_modules/bootstrap/scss/bootstrap";

// Then add additional custom code here

// Theme Font
@import "assets/scss/font-theme";

// ng-select styles
@import "~@ng-select/ng-select/themes/default.theme.css";

// @import '../node_modules/angular-archwizard/styles/archwizard.css';
body,
html {
    // background: #FAF8F6;
    // height: 100%;
}


// Spinner
.custom-loader {
    position: fixed;
    top:0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    background-color: hsla(0,0%,100%,.7);
    z-index: 99999;
}
.justify-content-center {
    justify-content: center!important;
}
.text-primary {
    color: #2B3278!important;
}
.spinner-border {
    background: url(~/src/assets/images/spinner.gif) no-repeat;
    width: 32px;
    height: 32px;
    background-size: cover;
    border:none !important;
    transition: none !important;
    transform: none !important;
    position: relative;
    top:50%;
}
.visorspinner-border {
    background: url(~/src/assets/images/Preloader.gif) no-repeat;
    width: 35px;
    height: 35px;
    background-size: cover;
    border: none !important;
    transition: none !important;
    transform: none !important;
    position: relative;
    top:50%;
}
.sr-only {
    border: 0;
    clip: rect(0,0,0,0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.sr-only {
    white-space: nowrap;
}
.btn-primary-careeco{
    background: #5CA6DB 0% 0% no-repeat padding-box;
    border-radius: 10px;
    text-align: center;
    font: normal normal bold 14px/19px 'SourceSansPro-r';
    letter-spacing: 0px;
    color: #FFFFFF;
    border: none;
    padding: 10px 45px;
    &:hover{
        background-color: #1a154c;
       color:#fff;
       border-color: #1a154c;
   }
    &:active{
        border: none;
        box-shadow: none;
    }
}


.btn-primary-visor{
    background-color: #4425F8 !important;
    color: #ffffff !important;
    border-radius: 6px !important;
    font-size: 14px !important;
    font-weight: 500;
    font-family: "Poppins" !important;
    text-transform: capitalize !important;
    padding: 5px 22px !important;
    height: 33px;
    &:hover {
        background-color: #4425F8 !important;
        color: #ffffff !important;
        border-color: #4425F8 !important;
    }

}
.form-check-input:checked {
    background-color: #5CA6DB;
    border-color: #5CA6DB;
}

.form-select2 .ng-select .ng-select-container,
.form-select2  .ng-select-container{
    height: 32px !important;
}
input::-webkit-date-and-time-value{ text-align:left !important; }
@media screen and (min-width:300px) {
    .ng-dropdown-panel{
        z-index: 9 !important;
    }
    .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup, 
    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
        text-overflow: unset !important;
        white-space: normal !important;
        line-height: 16px;
    }
}