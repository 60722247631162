app-schedule-appointment{
    #schedule-appointment{
        margin: 45px 0px;
        position: relative;
        padding-bottom: 100px;
        .new-appointment-sec{
            width: 51%;
            margin: 0 auto;
            
            h5{
                font: normal normal bold 15px/22px Arial, Helvetica, sans-serif;
                letter-spacing: 0px;
                color: #363636;
                text-transform: uppercase;
            }
            .facilityHeader{
                h4{
                    text-align: center;
                    font: normal normal bold 14px/27px Arial, Helvetica, sans-serif;
                    letter-spacing: 0px;
                    color: #fff;
                    margin-bottom: 10px;
                }
                .facilityAddress{
                    text-align: center;
                    font: normal normal normal 14px/19px 'SourceSansPro-sm';
                    letter-spacing: 0px;
                    color: #fff;
                }
            }
    
            .bg-style{
                background: #FFFFFF 0% 0% no-repeat padding-box;
                // box-shadow: 0 2px 10px 0 rgb(255, 255, 255);
                border-radius: 20px;
                padding: 30px;
                margin-bottom: 20px;
            }
            .terms-conditions{
                width: 73%;
                margin: 0 auto;
                margin-top: 20px;
            }
        }
        .required, .error{
            color: red;
        }
        .cameranotes{
            font: normal normal normal 14px/19px 'SourceSansPro-sm';
            letter-spacing: 0px;
            color: #848DA5;
        }
        .review-sytems .categories{
            padding-bottom: 15px;
            border-bottom: 1px solid rgba(0,0,0,0.1);
            margin-bottom: 15px;
            h5{
                font: normal normal 600 16px/22px Arial, Helvetica, sans-serif;
                letter-spacing: 0px;
                color: #22343D;
            }
        }
    }
    .continue-btn, .ok-book{
        background: #5CA6DB 0% 0% no-repeat padding-box;
        border-radius: 10px;
        text-align: center;
        font: normal normal bold 14px/19px Arial, Helvetica, sans-serif;
        letter-spacing: 0px;
        color: #FFFFFF;
        border: none;
        padding: 10px 45px;
        border-color: #5CA6DB;
        &:hover{
             background-color: #1a154c;
            color:#fff;
        }
        &:active{
            border: none;
            box-shadow: none;
        }
    }
    .ok-book{
        padding: 10px 20px;
    }
    .back-btn{
        background: #848DA5 0% 0% no-repeat padding-box !important;;
        border-radius: 10px;
        text-align: center;
        font: normal normal bold 14px/19px Arial, Helvetica, sans-serif !important;;
        letter-spacing: 0px;
        color: #FFFFFF;
        border: none;
        padding: 10px 45px;
        &:hover{
            border: none;
        }
        &:active{
            border: none;
        }
    }
    
    label{
        font: normal normal normal 14px/19px Arial, Helvetica, sans-serif;
        letter-spacing: 0px;
        color: #22343D;
    }
    #book-appointment-modal, #doctors-modal{
        background-color: rgba(0,0,0,0.1);
    }
    .modal-content{
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 5px 25px 70px #22343d1a;
        border-radius: 20px;
    }
    .btn-primary:disabled, .btn-primary.disabled{
        background-color: #5CA6DB;
        border-color: #5CA6DB;
        opacity: 0.7;
    }
    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option{
        font-size: 13px;
    }
}
.btn-primary{
    background-color: #5CA6DB;
    border-color: #5CA6DB;
    &:disabled{
        opacity: 0.7;
    }
}

.terms-conditions{
    // width: 100%;
    .form-check-label {
        color: #fff;
    }
}
@media(max-width:821px){
    app-schedule-appointment {
        #schedule-appointment{
            padding-bottom: 200px;
            .new-appointment-sec{
                width: 100%;
                margin: 0 auto;
                // max-height: calc(100vh - 300px);   
                // overflow-y: scroll; 
                // padding: 12px;           
                // max-height: calc(100vh - 300px);               
                .terms-conditions{
                    width: 100%;
                    .form-check-label {
                        color: #fff;
                    }
                }
            }
            #demographics-details {
                max-height: calc(100vh - 400px);
                overflow-y: scroll;
                // padding: 12px;
                // margin-top: 25px;
                // padding-top: 0;
            }
        }
    }
}
@media(max-width:1900px){
    app-schedule-appointment {
        // .new-appointment-sec{
        //     max-height: calc(100vh - 240px);
        //     overflow-y: scroll;
        //     padding: 12px;
        // }
        #demographics-details {
            max-height: calc(100vh - 240px);
            overflow-y: scroll;
            padding: 12px;
            margin-top: 25px;
            padding-top: 0;
        }
        .error{
            font-size: 14px;
        }
    }
}
@media(max-width:1199px){
    app-schedule-appointment {
        #schedule-appointment{
            .new-appointment-sec{
                width: 100%;
                margin: 0 auto;
                max-height: calc(100vh - 300px);   
                overflow-y: scroll; 
                padding: 12px;          
               
            }
        }
    }
}