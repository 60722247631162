app-payments-bill{
    .payments-sec{
        display: flex;
        align-items: center;
        height: auto;
        margin-top: 50px;
    }
    .feedback-check{
        width: 60%;
        margin: 0 auto;
        margin-bottom: 45px;
        padding: 35px;
        background-color: #fff;
        border-radius: 15px;
        box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.1);
        position: relative;
        // top: 70px;
        form.visit-exp-form{
            background: #d7ebe0;
            padding: 30px;
            border-radius: 15px;
        }
        label.smiley-check{
            width: 75%;
            // padding: 12px 0px;
            height: 80px;
        }
        .response-content{
            margin-right: 12px;
            margin-bottom: 10px;
        }
        .btn-group > .btn-check:checked + .btn {
            background: #5CA6DB;
            color: #fff;
            border: none;
        }
        .logo-center{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 20px;
            a{
                h5{
                    padding-top: 15px; 
                    padding-left: 8px;
                    font-size: 28px !important;
                    font: normal normal bold 16px/22px 'SourceSansPro-r';
                    letter-spacing: 0px;
                    color: #363636;
                }
            }
        }
    }
    .btn-lg {
        width: 100%;
    }
    
    .btn-light,
    .btn-check:checked {
        background-color: #fff;
    }

    .continue-btn {
        text-align: right;
        background: #5CA6DB 0% 0% no-repeat padding-box;
        font: normal normal bold 14px/19px 'SourceSansPro-r';
        color: #FFFFFF;
        border: none;
        // padding: 10px 45px;
        // float: right;
        padding: 12px 35px;
        &:hover {
            border: none;
            background: #1a154c 0% 0% no-repeat padding-box;
        }
        &:active, &:focus-within {
            border: none;
            box-shadow: none;
            background: #1a154c 0% 0% no-repeat padding-box;
        }
        &:disabled{
            color: #fff;
            background-color: #cccccc;
            border-color: #cccccc;
        }
    }
    .form-control{
        height: 47px;
    }
    // global checkbox custome styles
        .form-check-input[type="checkbox"] {
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05) !important;
            padding: 10px !important;
            border-radius: 3px !important;
            border: 2px solid #5CA6DB !important;
            cursor: pointer;
        }
        .form-check-input[type="checkbox"]:checked {
            background-color: #5CA6DB !important;
            border: 2px solid #5CA6DB !important;
            padding: 10px !important;
        }

        .form-check-input[type="checkbox"]:disabled {
            border-color: #eee !important;
            color: #ccc !important;
        }

        .patient-info-sec{
            .name{
                font: normal normal bold 18px/24px 'SourceSansPro-r';
                letter-spacing: 0px;
                color: #22343D;
            }
            .gender-age{
                font: normal normal normal 14px/19px 'SourceSansPro-sm';
                letter-spacing: 0px;
                color: #848DA5;
                margin-left: 15px;
            }
            .cc-names{
                font: normal normal normal 14px/19px 'SourceSansPro-sm';
                letter-spacing: 0px;
                color: #22343D;
                margin-right: 15px;
            }
        }
}

@media(max-width:991px){
    app-payments-bill{
        .payments-sec{
            height: auto;
        }
        .feedback-check{
            width: 100%;
            margin: 0 auto;
            margin-bottom: 45px;
            padding: 15px;
            background-color: #fff;
            border-radius: 15px;
            label.smiley-check{
                width: 80%;
            }
        }
        .pr-1 {
            padding-right: 12px !important;
        }
        .pl-1 {
            padding-left: 12px !important;
        }
        #payment-form {
            padding: 0px !important;
            .main-payments-form {
                padding: 0px !important;
            }
        }
    }
}

@media (max-width:574px){
    app-payments-bill{
        .payments-sec{
            height: auto;
        }

}
}